
















































































import mapValues from 'lodash/mapValues';
import {
  getSuperAdminGameProviders,
  updateSuperAdminGameProviders,
  getSuperAdminGameProviderOperators
} from '@/api/SuperAdmin';
import type {
  SuperAdminGameProvider,
  SuperAdminGameProviderData,
  SuperAdminGameProviderOperator
} from '@/api/schema';
import { AxiosError } from 'axios';

export interface SuperadminProvidersData {
  providers: SuperAdminGameProvider[];
  providersOperators: { [key: string]: SuperAdminGameProviderOperator[] };
  changingProviders: string[];
  providersFilters: { [key: string]: string | null };
  loading: {
    providers: boolean;
  };
  operatorTypes: { [key: string]: string | null[] }[];
}

export default {
  name: 'SuperAdminProvidersProxyIntegration',
  data(): SuperadminProvidersData {
    return {
      providers: [],
      providersFilters: {},
      providersOperators: {},
      changingProviders: [],
      loading: {
        providers: false
      },
      operatorTypes: [
        { text: 'All operators', value: null },
        { text: 'Prod operators', value: 'prod' },
        { text: 'Test operators', value: 'test' }
      ]
    };
  },
  computed: {
    operatorsByProviders(): {
      [key: string]: SuperAdminGameProviderOperator[];
    } {
      return mapValues(
        this.providersOperators,
        (operators: SuperAdminGameProviderOperator, name: string) => {
          return this.getFiltredProviderOperators(operators, name);
        }
      );
    }
  },
  created(): void {
    this.getProvidersData();
  },
  methods: {
    getFilterValue(name: string): string | null {
      return this.providersFilters[name] || null;
    },
    setFilterValue(name: string, value: string | null): void {
      this.providersFilters = {
        ...this.providersFilters,
        [name]: value
      };
    },
    getFiltredProviderOperators(
      operators: SuperAdminGameProviderOperator[],
      name: string
    ): SuperAdminGameProviderOperator[] {
      const filterStatus = this.providersFilters[name];

      if (!filterStatus) return operators;

      return operators.filter(
        (operator: SuperAdminGameProviderOperator): boolean => {
          return operator.test === (filterStatus === 'test');
        }
      );
    },
    getProvidersData(): void {
      this.loading.providers = true;
      getSuperAdminGameProviders()
        .then((data: SuperAdminGameProvider[]) => {
          this.providers = data;
        })
        .catch((error: AxiosError) => {
          this.providers = [];
          console.log(
            `Superadmin providers data loading - `,
            error.response?.data
          );
        })
        .finally((): void => {
          this.loading.providers = false;
        });
    },
    changeProvider(swichStatus: boolean, name: string): void {
      this.toggleChangesLoading(name);
      updateSuperAdminGameProviders(name, swichStatus)
        .catch((error: AxiosError) => {
          console.log(`Update provider data - `, error.response?.data);
        })
        .finally((): void => {
          this.getProvidersData();
          this.toggleChangesLoading(name);
        });
    },
    toggleChangesLoading(name: string): void {
      if (this.changingProviders.includes(name)) {
        this.changingProviders = this.changingProviders.filter(
          (loadingId: string) => loadingId !== name
        );
        return;
      }
      this.changingProviders = [...this.changingProviders, name];
    },
    isChangesLoading(id: number): boolean {
      return this.changingProviders.includes(id);
    },
    changePanel(providerName: string): void {
      if (!this.providersOperators[providerName]) {
        this.getProviderOperators(providerName);
      }
    },
    getProviderOperators(providerName: string): void {
      getSuperAdminGameProviderOperators(providerName)
        .then((data: SuperAdminGameProviderData) => {
          this.$set(this.providersOperators, providerName, [
            ...data[0].operators
          ]);
        })
        .catch((): void => {
          this.$set(this.providersOperators, providerName, []);
        });
    }
  }
};
